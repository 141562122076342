import React from "react";

import posed from "react-pose";

import Layout from "../components/layout";
import SEO from "../components/seo";
import dogIllustration from "../images/dog-illustration.svg";

const P = posed.p({
  enter: { y: 0, opacity: 1 },
  exit: { y: 50, opacity: 0 }
});

const Transition = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 }
});

const AboutPage = () => (
  <Layout>
    <SEO
      title="About"
      keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
    />

    <Transition>
      <div className="flex flex-col md:flex-row items-center">
        <div className="md:w-2/3 md:mr-8">
          <P className="border-l-4 border-grey-darkest font-serif leading-loose pl-4 text-justify">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius vitae
            natus expedita repellat obcaecati laborum delectus commodi illum,
            est dolorem fuga alias voluptatum saepe quibusdam rerum ullam
            sapiente, esse sint!
          </P>

          <P className="font-bold mt-4 text-right text-xs uppercase">
            — Lorem Ipsum
          </P>
        </div>

        <div className="w-2/3 md:w-1/3">
          <img src={dogIllustration} alt="A dog relaxing" />
        </div>
      </div>
    </Transition>
  </Layout>
);

export default AboutPage;
